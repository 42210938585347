import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'session',
  initialState: {
    isLoggedIn: false,
    user: getUserInitialState(),
    formInfo: null
  },
  reducers: {
    logoutSuccess: (state): void => {
      state.user = null;
      localStorage.clear();
    },
    saveUserInfo: (state, action): void => {
      state.user = action.payload
      localStorage.setItem('userInfo', JSON.stringify(action.payload));
    },
    loginState: (state, action): any => {
      state.isLoggedIn = action.payload
    }
  }
}
);

function getUserInitialState(): any {
  const userInfoStorage = localStorage.getItem('userInfo');
  return userInfoStorage ? JSON.parse(userInfoStorage) : null;
}

export const { logoutSuccess, saveUserInfo, loginState } = slice.actions;
export const session = (state): any => state.session;
export default slice.reducer;
