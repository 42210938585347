import { ReactElement, useEffect, useState } from 'react';
import NewLayout from '../../components/Layout/NewLayout';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentSection } from '../../slices/navSlice';
import { useQuery } from '@apollo/client';
import { APPOINTMENTS_BY_ORIENTEER } from '../../queries/Appointment';
import { session } from '../../slices/sessionSlice';
import { getTime } from '../../utils/utilsDate';
import { Roles } from '../../utils/Roles';
import AgendaEditAppointment from '../../components/Agenda/AgendaEditAppointment';
import { useHistory, useParams } from 'react-router-dom'
import LoadingSpinner from '../../utils/spinner';
import { GET_ORIENTEERS_FOR_OPTIONS } from '../../queries';
import { GET_ORIENTEDS_FOR_OPTIONS } from '../../queries/Oriented';
import { GET_ALL_PROGRAMS } from '../../queries/Program';

const AgendaEditPage =(): ReactElement => {
  const { appointmentId } = useParams()
  const { user } = useSelector(session);
  const history = useHistory()
  const role = localStorage.getItem('role');
  const dispatch = useDispatch();
  const [confirmedAppoitment, setConfirmedAppoitment] = useState<null | Array<any>>(null);
  const [programs, setPrograms] = useState<any>([]);
  const { data: dataOrienteer, loading: loadingOrienteer } = useQuery(GET_ORIENTEERS_FOR_OPTIONS)
  const { data: dataOriented, loading: loadingOriented } = useQuery(GET_ORIENTEDS_FOR_OPTIONS);
  const { data: allProgramsData, loading: loadingPrograms } = useQuery(GET_ALL_PROGRAMS);
  const optionsOrientedInitialState = {
    value: '',
    label: '',
    programs:[''],
    dni:''
  }
  const optionsOrienteerInitialSate = {
    value: '',
    label: ''
  }
  const [optionsOriented, setOptionsOriented] = useState([optionsOrientedInitialState]);
  const [optionsOrienteer, setOptionsOrienteer] = useState([optionsOrienteerInitialSate]);
  
  useEffect(()=>{
    dispatch(setCurrentSection('Agenda'))
  })

  const eventsOfOrienteer = useQuery(APPOINTMENTS_BY_ORIENTEER, {
    variables: {
      id: user.id,
    },
  });
  const { data, loading } = eventsOfOrienteer;

  useEffect(()=>{
    if (!loadingPrograms && allProgramsData) {
      setPrograms(allProgramsData.getAllPrograms);
    }
  })

  useEffect(() => {
    if (!loading) {
      const appointments = data.getAppointmentsByOrienteer;
      const formatterConfirmed = appointments.map((appointment) => {
        let userArr: any = [];
        if (appointment.confirmedOrienteers) {
          for (var i = 0; i < appointment.confirmedOrienteers.length; i++) {
            userArr.push(appointment.confirmedOrienteers[i].user);
          }
        }
        return {
          date: appointment.date,
          time: getTime(appointment.date),
          name: appointment.name,
          id: appointment.id,
          detail: appointment.detail,
          orienteer: user.user.name,
          orienteerId: user ? user.id : undefined,
          timeSpan: appointment.timeSpan,
          orienteds:
            appointment.orienteds[0] && showParticipants(appointment.orienteds),
          orientedId: appointment.orienteds[0].user
            ? appointment.orienteds[0].user.id
            : undefined,
          orientedsDropdown: appointment.orienteds,
          orienteersDropdown: userArr,
          program: appointment.program,
          googleMeetLink: appointment.googleMeetLink
        };
      });
      setCurrentAppointment(formatterConfirmed);
    }
  }, [data]);

  useEffect(() => {
    if (!loadingOrienteer && dataOrienteer) {
      const formatter = dataOrienteer.allOrienteerForOptions.map(
        (orienteer) => {
          return {
            label: orienteer.user.name,
            value: orienteer.id,
          }
        }
      )
      const alphabeticalSort = [...formatter].sort(function (a, b) {return (a.label.localeCompare(b.label))})
      setOptionsOrienteer(alphabeticalSort)
    }
  }, [dataOrienteer])

  useEffect(() => {
    if (!loadingOriented && !!dataOriented) {
      const formatter = dataOriented.getAllOrientedsWithUser.map((oriented) => {
        return {
          label:  `${oriented.user.name} (${oriented.dni})`,
          value: oriented.id,
          programs: oriented.paymentPlans.map((program)=>{return program.programSlug}),
          dni: 'DNI: ' + oriented.dni
        };
      });
      setOptionsOriented(formatter);
    }
  }, [dataOriented]);

  const showParticipants = (orientedArray: Array<any>) => {
    let participants: any = [];
    orientedArray.map((oriented) => participants.push(oriented.user.name));
    return participants.join(', ');
  };

  const setCurrentAppointment =(AppointmentList) => {
    let AppointmentFound = AppointmentList.find((AppointmentSelected) => AppointmentSelected.id == appointmentId )
    if (AppointmentFound != undefined) {
      setConfirmedAppoitment(AppointmentFound)
    }

  }

  const moveBack = () => {
    history.goBack()
  }

  return (
    <NewLayout>
      {!confirmedAppoitment ? 
        <LoadingSpinner/> 
        :
        <div className="w-full md:ml-11">
          <AgendaEditAppointment appointment={confirmedAppoitment} programs={programs} optionsOriented={optionsOriented} optionsOrienteer={optionsOrienteer} role={Roles.ORIENTEER == role ? Roles.ORIENTEER : null } back={moveBack}/>
        </div>}
    </NewLayout>            
  )

}

export default AgendaEditPage;