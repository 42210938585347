export const daysFromToday = (date): number => {
  const today = new Date()
  const appointmentDate = new Date(date).getTime()
  const result = appointmentDate - today.getTime()
  return Math.round(result / (1000 * 60 * 60 * 24))
}

export const getDate = (date): string => {
  const dateAppointment = new Date(date)
  const month = dateAppointment.getMonth()+1
  return dateAppointment.getDate()+ '/'+month+'/'+dateAppointment.getFullYear()
}

export const noTime = (date):Date =>{
  let noTimeDate = new Date(date)
  noTimeDate.setHours(0,0,0,0)
  return noTimeDate
}

export const getScriptDate = (date): string => {
  const day = date.getDate()
  const month = date.getMonth() + 1
  const year = date.getFullYear()
  return `${month}-${day}-${year}`
}

export const formatterDate = (date): string => {
  const dateAppointment = date.split('/')
  return dateAppointment[1] + '/' + dateAppointment[0] + '/' + dateAppointment[2]
}

export const formaterToSubmit = (date) :string =>{
  const dateAppointment = date.split('/')
  return dateAppointment[2] + '-' + dateAppointment[1] + '-' + dateAppointment[0]
}

export const lastMinute = (date):Date => new Date(date.getFullYear(),date.getMonth(),date.getDate(),23,59,59)

export const getTime = (date): string => {
  const dateAppointment = new Date(date)
  const addCeroIfIHave = dateAppointment.getMinutes() < 10 ? '0' : ''
  return dateAppointment.getHours() + ':' + addCeroIfIHave + dateAppointment.getMinutes()
}

export const halfHourlyRange = (start,end) : string[] =>{
  let times : string[] = []
  for (let i = start; i <= end ; i++) i!==end? times.push( i+':00' ,i+':30' ):times.push( i+':00')
  return times
}
