import React, { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { session } from '../../slices/sessionSlice';
import Table from '../UI/Table';
import { getTime } from '../../utils/utilsDate';
import TittleInProgress from '../UI/TittleInProgress';
import { useQuery } from '@apollo/client';
import { APPOINTMENTS_BY_ORIENTED } from '../../queries/Appointment';
import { format } from 'date-fns';
import LoadingSpinner from '../../utils/spinner';
import { useHistory } from 'react-router-dom';
import InputTag from '../UI/InputTag';

const AgendaTableOriented = (): ReactElement => {
  const history = useHistory();
  const [programOptions, setProgramOptions] = useState<any>();
  const [appointmentsFiltered, setAppointmentsFiltered] = useState<null | Array<any>>(null);
  const [confirmedAppointment, setconfirmedAppointment] = useState<null | Array<any>>(null);
  const [ optionFilterSelected, setOptionFilterSelected] = useState<null | Array<any>>(null);
  const { user } = useSelector(session);
  
  const orderColummns: Array<{
    id: string;
    desc: boolean;
  }> = [ 
    {
      id: 'date',
      desc: true
    }
  ];


  const eventsOfOriented = useQuery(APPOINTMENTS_BY_ORIENTED, {
    variables: { id: user.id }
  })  
  
  useEffect(() => {
    //sets appointmentsFiltered containing the table component data
    if (confirmedAppointment != null) {
      setAppointmentsFiltered(
        confirmedAppointment.filter((appointment) => {
          return optionFilterSelected?.some((program)=>{return program.value == appointment.program.id});
        })
      );
    }
  }, [programOptions, optionFilterSelected]);

  useEffect(() => {
    if (!eventsOfOriented.loading) {
      const formatterConfirmed = eventsOfOriented.data.getAppointmentsByOriented.map(
        appointment => {
          let userArr :any = []
          if(appointment.confirmedOrienteers){
            for(var i = 0; i < appointment.confirmedOrienteers.length; i++){
              userArr.push(appointment.confirmedOrienteers[i].user)
            }
          }
          return {
            date: appointment.date,
            time: getTime(appointment.date),
            name: appointment.name,
            id: appointment.id,
            comments: appointment.comments,
            detail: appointment.detail,
            interestLinks: appointment.interestLinks,
            orienteds: appointment.orienteds,
            orienteers: userArr.map(item => item.name).join(', '),
            program: appointment.program,
          };
        }
      );
      const programList = formatterConfirmed.map((item)=>{
        return {
          value:item.program.id,
          label: item.program.name
        }
      })
      const programListFiltered = programList.filter((item, index) => {
        const itemIndex = programList.findIndex((item)=>{return item.value == programList[index].value})
        return itemIndex === index
      });
      setProgramOptions(programListFiltered)
      setconfirmedAppointment(formatterConfirmed);
    }
  }, [eventsOfOriented]);

  useEffect(() => {
    eventsOfOriented.refetch();
  }, [eventsOfOriented.data]);

  useEffect(()=>{
    setOptionFilterSelected(programOptions)
  }, [programOptions])
      

  const handleShowAppointmentDetail = (selected): void => {
    const appointmentId = selected.id;
    history.push(`/agenda/${appointmentId}/oriented/details`);
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Fecha',
        accessor: 'date',
        Cell: ({ value }):string => {return format(new Date(value), 'dd/MM/yyyy')}
      },
      {
        Header: 'Horario',
        accessor: 'time'
      },
      {
        Header: 'Evento',
        accessor: 'name'
      },
      {
        Header: 'Orientadores',
        accessor: 'orienteers',
        disableSortBy: true
      },
      {
        Header: 'Programa',
        accessor: 'program.name',
      },
    ],
    []
  );

  // replaced use of <Pagination/> component with <Table/> to avoid pagination bug in tables 
  return (
    <div className="h-full w-full md:ml-11 mb-20">
      <div>
        <div className="w-full flex flex-col">
          <div className={'flex justify-between w-full mb-14'}>
            <TittleInProgress text={'Filtrar por programa'} width={'w-auto'} />
          </div>
          {
            optionFilterSelected && 
            <InputTag
              options={programOptions} 
              state={[ optionFilterSelected, setOptionFilterSelected]} 
              defaultOptions={programOptions} 
              placeholder={'Elegí tus programas...'} 
            />
          }
          <TittleInProgress text={'Encuentros agendados'} width={'w-80'} />
          {
            appointmentsFiltered != null && confirmedAppointment ?
              <div className={'w-5/6'}>
                <Table 
                  columns={columns} 
                  data={appointmentsFiltered} 
                  edit={handleShowAppointmentDetail} 
                  initialRows={10} 
                  orderColumnArray={orderColummns} />
              </div>
              : 
              <LoadingSpinner/> 
          }
        </div>        
      </div>
    </div>
  );
};

export default AgendaTableOriented;
