import { ReactElement } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Login from '../components/Login';
import NoMatch from '../components/NoMatch';
import AdmissionsPage from '../pages/Admin/AdmissionsPage';
import  AgendaPage  from '../pages/Agenda/AgendaPage';
import AssignedPage from '../pages/Admin/AssignedPage';
import CreateActivityPage from '../pages/Library/CreateActivityPage';
import LibraryPage from '../pages/Library/LibraryPage';
import  HelpPage  from '../pages/Help/HelpPage';
import  MessagesPage  from '../pages/Messages/MessagesPage';
import  PaymentsPage  from '../pages/Payments/PaymentsPage';
import AccountingReportsPage from '../pages/Payments/AccountingReportsPage';
import HomePage from '../pages/Home/HomePage';
import ProfilePage from '../pages/Profile/ProfilePage';
import OrientedPage from '../pages/Oriented/OrientedPage';
import OrientedDetailsPage from '../pages/Oriented/OrientedDetailsPage';
import FinalMaterialPage from '../pages/Oriented/FinalMaterialPage';
import OrientedAppointmentPage from '../pages/Oriented/OrientedAppointmentPage';
import ProfileEditPage from '../pages/Profile/ProfileEditPage';
import ChangePasswordPage from '../pages/Profile/ChangePasswordPage';
import AdmissionsDetailsPage from '../pages/Admin/AdmissionsDetailsPage';
import AssignedDetailsPage from '../pages/Admin/AssignedDetailsPage';
import MyProgramsPage from '../pages/Oriented/MyProgramsPage';
import ProfessionLabPage from '../pages/Oriented/ProfessionLabPage';
import AdmissionsFormPage from '../pages/Admin/AdmissionsFormPage';
import ProgramAppointmentsPage from '../pages/Oriented/ProgramAppointmentsPage';
import AdminOrientedInfoPage from '../pages/Admin/AdminOrientedInfoPage';
import PaymentsDetailPage from '../pages/Payments/PaymentsDetailPage';
import NewMessagePage from '../pages/Messages/NewMessagePage';
import AppointmentsDetailsPage from '../pages/Oriented/AppointmentsDetailsPage';
import AgendaOrientedDetailsPage from '../pages/Agenda/AgendaOrientedDetailsPage';
import ActivitiesFormPage from '../pages/Activities/ActivitiesFormPage';
import NewAppointmentPage from '../pages/Agenda/NewAppointmentPage';
import AgendaEditPage from '../pages/Agenda/AgendaEditPage';
import AdmissionsEditPage from '../pages/Admin/AdmissionsEditPage';
import AgendaAdminPage from '../pages/Admin/AgendaAdminPage';
import AdminAgendaViewPage from '../pages/Agenda/AdminAgendaViewPage';
import ProfessionLabListPage from '../pages/Oriented/ProfessionLabListPage'
import ProgramsPage from '../pages/Library/ProgramsPage';
import ProgramsNewPage from '../pages/Library/NewProgramsPage';
import ActivityPage from '../pages/Activities/ActivityPage';
import ProgramsEditPage from '../pages/Library/ProgramsEditPage';
import EditActivityPage from '../pages/Library/EditActivityPage';
import AdminActivityPage from '../pages/Library/AdminActivityPage';
import GoogleConfigPage from '../pages/Profile/GoogleConfigPage';
import OrienteerLogin from '../components/OrienteerLogin';
import LoginRedirect from '../pages/Auth/LoginRedirect';
import GoogleApiAccessPage from '../pages/Auth/GoogleApiAccessPage';
import GoogleApiAccessRedirectPage from '../pages/Auth/GoogleApiAccessRedirectPage';
import PaymentPlanPage from '../pages/Payments/PaymentPlanPage';


const AppRouter = (): ReactElement => {

  return (
    <BrowserRouter getUserConfirmation={() => {
      /* Empty callback to block the default browser prompt */
    }}>
      <Switch>        
        <Route path='/login' component={Login} />
        <Route path='/orienteer/login' component={OrienteerLogin} />
        <Route path='/login_redirect' component={LoginRedirect}/>
        <Route path='/google/api-access/redirect' component={GoogleApiAccessRedirectPage}/>
        <Route path='/google/api-access' component={GoogleApiAccessPage}/>
        <Route path='/home' component={HomePage}/>
        <Route path='/admin-oriented/payment-plans/:orientedId' component={PaymentPlanPage}/>
        <Route path='/admin-oriented-info/:orientedId' component={AdminOrientedInfoPage}/>
        <Route path='/my-programs/:programSlug/appointments/:appointmentId/activity/:activityId' component={ActivitiesFormPage}/>
        <Route path='/my-programs/:programSlug/profession-labs/:labId' component={ProfessionLabPage}/>
        <Route path='/my-programs/:programSlug/profession-labs' component={ProfessionLabListPage}/>
        <Route path='/my-programs/:programSlug/appointments/:id' component={AppointmentsDetailsPage}/>
        <Route path='/my-programs/:programSlug/appointments' component={ProgramAppointmentsPage}/>
        <Route path='/my-programs/:programSlug' component={MyProgramsPage}/>
        <Route path='/profile/edit' component={ProfileEditPage}/>
        <Route path='/profile/google-config' component={GoogleConfigPage}/>
        <Route path='/profile/change-password' component={ChangePasswordPage}/>
        <Route path='/profile' component={ProfilePage}/>

        {/* Para editar eventos en la agenda como orientador & admin */}
        <Route path='/agenda/admin/appointment/view/:appointmentId' component={AdminAgendaViewPage}/>
        <Route path='/agenda/appointment/edit/:appointmentId' component={AgendaEditPage}/>
        {/* Como Orientado */}
        <Route path='/agenda/:appointmentId/oriented/details' component={AgendaOrientedDetailsPage}/>

        {/* Como Orientador y admin */}
        <Route path='/agenda/appointment/new' component={NewAppointmentPage}/>

        {/* Para admin */}
        <Route path='/agenda/admin' component={AgendaAdminPage}/>

        {/* Para orientador y orientado! */}
        <Route path='/agenda' component={AgendaPage}/>
        

        <Route path='/messages/new' component={NewMessagePage} />
        <Route path='/messages' component={MessagesPage} />
        <Route path='/activity' component={ActivityPage} />
        <Route path='/library/activity/edit/:id' component={EditActivityPage} />
        <Route path='/library/activity/new' component={CreateActivityPage} />
        <Route path='/library/programs/edit/:id' component={ProgramsEditPage} />
        <Route path='/library/programs/new' component={ProgramsNewPage} />
        <Route path='/library/programs' component={ProgramsPage} />
        <Route path='/library/activity' component={AdminActivityPage} />
        <Route path='/library' component={LibraryPage} />
        <Route path='/payments/:paymentId/details' component={PaymentsDetailPage}/>
        <Route path='/payments/registered-accounting-reports' component={AccountingReportsPage}/>
        <Route path='/payments' component={PaymentsPage}/>
        <Route path='/help' component={HelpPage}/>
        <Route path='/admissions/form/:id' component={AdmissionsEditPage}/>
        <Route path='/admissions/form/' component={AdmissionsFormPage}/>
        <Route path='/admissions/:orientedId' component={AdmissionsDetailsPage}/>
        <Route path='/admissions' component={AdmissionsPage}/>
        <Route path='/assigned/:orientedId' component={AssignedDetailsPage}/>
        <Route path='/assigned' component={AssignedPage}/>
        <Route path='/oriented/:orientedId/details/:appointmentId' component={OrientedDetailsPage}/>
        <Route path='/oriented/:orientedId/final-material/:appointmentId' component={FinalMaterialPage}/>
        <Route path='/oriented/:orientedId/appointments' component={OrientedAppointmentPage}/>
        <Route path='/oriented' component={OrientedPage}/>
        <Route path='/' component={HomePage}/>
        <Route component={NoMatch} />
      </Switch>
    </BrowserRouter>
  );
}

export default AppRouter;

