import { getDate, getTime } from './utilsDate'

export const isFinished = (appointment):boolean => {
  return appointment.status === 'FINISHED'

}

export const isOlder = (appointment):boolean => {
  const now = new Date()
  const appointmentDate = new Date(appointment.date)
  const duration = appointmentDate.setMinutes(appointmentDate.getMinutes() + appointment.timeSpan)
  const endEventDate = new Date(duration)
  const result = (now > endEventDate)
  if(result) {
    appointment.statusVisual = 'TIMEUP'
    return result
  }
  return false
}

export const isInProgress = (appointment):boolean => {
  if(appointment.status !== 'FINISHED' && appointment.timeSpan != 0){
    const now = new Date()
    const eventDate = new Date(appointment.date)
    const duration = eventDate.setMinutes(eventDate.getMinutes() + appointment.timeSpan)
    const result = ((now > new Date(appointment.date)) && (now < new Date(duration)))
    if(result){
      appointment.statusVisual = 'INPROGRESS'
      return result
    }
  }
  return false
}


export const formatData = (appointments): Array<any> => {
  return appointments.map(
    appointment => {
      return {
        date: getDate(appointment.date),
        time: getTime(appointment.date),
        name: appointment.name,
        id: appointment.id,
        comments: appointment.comments,
        detail: appointment.detail,
        interestLinks: appointment.interestLinks,
        orienteers: appointment.orienteers,
        program: appointment.program,
        status: isFinished(appointment) ? 'FINISHED' : appointment.status,
        statusVisual: appointment.statusVisual
      };
    }
  )
}